
import ApiRequestService from '@/services/ApiRequestService'
import SnippetsService from '@/services/SnippetsService'
import StorageService, {LANGUAGE_KEY, USER_ID} from '@/services/StorageService'
import Multiselect from "vue-multiselect";
import {Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, map} from "rxjs/operators";
import OrganizationsUtil from "@/utils/OrganizationsUtil";
import ValidateUtil from "@/utils/ValidateUtil";
import PaginatedSearchListUtil from "@/utils/PaginatedSearchListUtil";
import DateUtil from '@/utils/DateUtil'
import * as moment from "moment"
import * as momentTimezone from 'moment-timezone'

const TIMEZONE = 'Europe/Rome';

export default {

  components: {
    Multiselect
  },

  data() {
    let snippets = SnippetsService.getSnippetsByNamespaceAndLanguage('Bookings', StorageService.getFromStorage(LANGUAGE_KEY))

    return {
      status: 'all',
      page: 0,
      pageCount: 0,
      itemsPerPage: 10,
      bookingSnippet: snippets ?? {},
      entityList: [],
      paginatedEntitiesList: [],
      paginatedSearchedBookingsList: [],
      bookingsPaginatedList: [],
      searchedBookingList: [],
      searchedData: [],
      loadedPages: {},
      searchKey: '',
      searchField: '',
      dialog: false,
      dialogDelete: false,
      dialogError: false,
      submitted: false,
      eventsSubmitted: false,
      spaceSubmitted: false,
      errorMessage: '',
      editedIndex: -1,
      selectedFacility: '',
      selectedSpace: '',
      selectedChildSpace: '',
      selectedDate: '',
      selectedStartTime: '',
      selectedEndTime: '',
      selectedOrganization: '',
      selectedUser: '',
      selectedParticipants: [],
      selectedNewParticipants: [],
      selectedStatus: '',
      hasChildren: false,
      date: '',
      nowDate: new Date().toISOString().slice(0, 10),
      newParticipantEmail: '',
      maxNumberOfParticipants: 0,
      modal: false,
      menu2: false,
      availableDates: [],
      editedItem: {
        uuid: '',
        facilityName: '',
        facilityId: '',
        spaceName: '',
        spaceId: '',
        bookingDate: '',
        startTime: '',
        endTime: '',
        userName: '',
        userId: '',
        organizationId: '',
        participants: [],
        status: '',
      },
      defaultItem: {
        uuid: '',
        facilityName: '',
        facilityId: '',
        spaceName: '',
        spaceId: '',
        bookingDate: '',
        startTime: '',
        endTime: '',
        userName: '',
        userId: '',
        organizationId: '',
        participants: [],
        status: '',
        organizationName: '',
        organizationCredits: 0,
        spaceCredits: 0,
      },
      facilities: [],
      facilitySpaces: [],
      spaceChildren: [],
      dates: [],
      workingDays: [],
      startTimes: [],
      endTimes: [],
      organizations: [],
      allUsers: [],
      users: [],
      participants: [],
      newParticipants: [],
      statusList: ['active', 'canceled'],
      sortBy: 'bookingDate',
      editedPrefix: '',
      sortDesc: true,
      errorKey: '',
      searchedDataOriginal: '',
      pagesCount: 1,
      currentPage: 1,
      disableKeyField: true,
      bookingData: [],
      isAllowed: StorageService.getUserRoles() !== 'FacilityManager',
      rules: {
        email: value => {
          if (!value) {
            return true;
          }

          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

          if (!pattern.test(value)) {
            return 'Invalid e-mail.';
          }

          if (this.selectedNewParticipants.includes(value)) {
            return 'User with email has already added';
          }

          return true;
        },
      },
      errors: {
        statusError: '',
        facilityError: '',
        spaceError: '',
        childSpaceError: '',
        dateError: '',
        startTimeError: '',
        endTimeError: '',
        organizationError: '',
        userError: '',
        participantsError: '',
        newParticipantsError: ''
      }
    }
  },

  computed: {

    formTitle() {
      return this.editedIndex === -1 ? this.bookingSnippet.add_booking : this.bookingSnippet.edit_booking
    },

    headers() {
      const header = [
        {text: this.bookingSnippet.uuid, value: 'uuid', width: '0px', sortable: true},
        {
          text: this.bookingSnippet.facilityName,
          value: 'facilityName',
          width: '0px',
          sortable: true
        },
        {
          text: this.bookingSnippet.spaceName,
          value: 'spaceName',
          width: '0px',
          sortable: true
        },
        {
          text: this.bookingSnippet.organization,
          value: 'organizationName',
          width: '0px',
          sortable: true
        },
        {
          text: this.bookingSnippet.creditsCost,
          value: 'organizationCredits',
          width: '0px',
          sortable: false
        },
        {text: this.bookingSnippet.spaceName, value: 'spaceName', width: '10%', sortable: true},
        {
          text: this.bookingSnippet.bookingDate,
          value: 'bookingDate',
          width: '0px',
          sortable: true
        },
        {text: this.bookingSnippet.startTime, value: 'startTime', width: '0px', sortable: true},
        {text: this.bookingSnippet.endTime, value: 'endTime', width: '0px', sortable: true},
        {text: this.bookingSnippet.userName, value: 'userName', width: '0px', sortable: true},
        {text: this.bookingSnippet.status, value: 'status', width: '0px', sortable: true},
      ];

      if(this.isAllowed){
        header.push({text: this.bookingSnippet.actions, value: 'actions', width: '0px', sortable: false})
      }

      return header;
    },

    isEditAction() {
      return this.editedIndex !== -1
    },

  },

  watch: {
    dialog(val) {
      val || this.close()
    },

    dialogDelete(val) {
      val || this.closeDelete()
    },

    dialogError(val) {
      val || this.closeErrorDialog()
    },

    async searchKey(searchText) {
      if (searchText) {
        this.search$.next(searchText);
        this.searchField = searchText
      } else {
        this.searchField = ''
        this.searchedData = [];
        this.search$.next('');
      }
    },

  },

  async mounted() {
    this.validateUtil = new ValidateUtil(this.errors);

    this.initPaginatedDataUtil();

    await this.getDataList();

    await this.getFacilitiesFromApi();

    this.organizationsUtil = new OrganizationsUtil('api/admin-organizations', this.organizations, this.selectOrganization);

    await this.organizationsUtil.getOrganizations();

    this.loading = false;

    this.searchAction();

    this.setOpacityToNotActiveBookings();
  },


  methods: {

    initPaginatedDataUtil() {
      this.paginatedSearchListUtil = new PaginatedSearchListUtil(`api/admin-facility-bookings`, this.itemsPerPage, 'bookingDate', true);
      this.paginatedSearchListUtil.setDataUniqueField('uuid');
      this.paginatedSearchListUtil.setDataModel(this.defaultItem);
    },

    updateListData() {
      this.bookingData = this.paginatedSearchListUtil.entityData;
      this.pagesCount = this.paginatedSearchListUtil.pagesCount;
      this.searchedDataOriginal = this.paginatedSearchListUtil.searchedDataOriginal;
      this.paginatedSearchedBookingsList = this.paginatedSearchListUtil.paginatedSearchedEntityList;
      this.paginatedEntitiesList = this.paginatedSearchListUtil.paginatedEntitiesList;
      this.bookingsList = this.paginatedSearchListUtil.entityList;
      this.setOpacityToNotActiveBookings();

    },


    customSort(items) {
      return items;
    },

    async sortTable() {
      await Promise.resolve();
      await this.paginatedSearchListUtil.setSortColumn(this.sortBy, this.sortDesc);
      this.updateListData();
    },

    async statusChange(status) {
      await this.paginatedSearchListUtil.setAdditionalParam('status', status);
      this.currentPage = 1;

      if (this.searchField === '') {
        await this.paginatedSearchListUtil.getListData(this.currentPage);
        this.updateListData();

        return;
      }

      await this.paginatedSearchListUtil.getSearchedEntitiesPaginated(this.searchField, this.currentPage);
      this.updateListData();
    },

    async getDataList() {
      await this.paginatedSearchListUtil.getListData(this.currentPage);
      this.updateListData();
    },

    getValidators() {
      return [
        {method: 'isRequiredFieldValid', errorKey: 'facilityError', value: this.selectedFacility},
        {method: 'isRequiredFieldValid', errorKey: 'spaceError', value: this.selectedSpace},
        {
          method: 'isRequiredFieldValid',
          errorKey: 'organizationError',
          value: this.selectedOrganization
        },
        {method: 'isRequiredFieldValid', errorKey: 'dateError', value: this.selectedDate},
        {method: 'isRequiredFieldValid', errorKey: 'userError', value: this.selectedUser},
        {
          method: 'isStatusValid',
          errorKey: 'statusError',
          value: {
            editedIndex: this.editedIndex,
            newStatus: this.selectedStatus,
            oldStatus: this.editedItem.status
          }
        },
        {
          method: 'isChildSpaceValid',
          errorKey: 'childSpaceError',
          value: {selectedChildSpace: this.selectedChildSpace, hasChildren: this.hasChildren}
        },
        {
          method: 'isStartTimeValid',
          errorKey: 'startTimeError',
          value: {selectedStartTime: this.selectedStartTime, selectedEndTime: this.selectedEndTime}
        },
        {
          method: 'isEndTimeValid',
          errorKey: 'endTimeError',
          value: {selectedStartTime: this.selectedStartTime, selectedEndTime: this.selectedEndTime}
        },
        {
          method: 'areParticipantsValid',
          errorKey: 'participantsError',
          value: {
            selectedParticipants: [...this.selectedParticipants, ...this.selectedNewParticipants],
            maxNumberOfParticipants: this.maxNumberOfParticipants
          }
        },
      ]
    },

    allowedDates(val) {
      return this.workingDays.includes( new Date( val ).getDay() ) ? val : null
      if (this.dates.length) {
        return this.dates.includes(val);
      }

      return new Date();
    },

    isBookingEditable(item) {
      let bookingDate = new Date(`${item.bookingDate}`);
      let bookingTime = new Date(`${bookingDate.getFullYear()}-${bookingDate.getMonth() + 1}-${bookingDate.getDate()} ${item.startTime}`);
      return !(item.status === 'finished' || item.status === 'canceled' || bookingTime.getTime() < Date.now());
    },

    searchAction() {
      this.search$ = new Subject();
      this.search$.pipe(
        map(text => text?.toString()?.toLowerCase()),
        debounceTime(1000),
        distinctUntilChanged()
      ).subscribe(async (searchText) => {
        this.currentPage = 1;

        if (this.searchField === '') {
          await this.paginatedSearchListUtil.getListData(this.currentPage);
          this.updateListData();
          return;
        }

        await this.paginatedSearchListUtil.getSearchedEntitiesPaginated(searchText, this.currentPage);
        this.updateListData();
      })
    },

    async changePage(page) {
      this.currentPage = page;
      await this.paginatedSearchListUtil.changePage(this.searchField, page);
      this.updateListData();
    },

    async getFacilitiesFromApi() {
      let facilityData = await ApiRequestService.getRequest(`api/admin-facilities`);
      this.setFacilitiesList(facilityData);
    },

    async getFacilitiesSpacesApi(facilityId) {
      let spaceData = await ApiRequestService.getRequest(`api/admin-facility-bookings/bookable/facility/${facilityId}`);
      console.log('spaceData', spaceData)
      this.setFacilitySpacesList(spaceData);
    },

    async selectFacility(facility) {
      this.selectedFacility = facility;
      this.selectedSpace = '';
      this.selectedChildSpace = '';
      this.selectedDate = '';
      this.date = '';
      this.selectedStartTime = '';
      this.selectedEndTime = '';

      await this.getFacilitiesSpacesApi(facility.id);
      this.isDataValid();
    },

    async selectSpace(space) {
      this.selectedSpace = space;
      this.selectedChildSpace = '';
      this.selectedDate = '';
      this.selectedStartTime = '';
      this.selectedEndTime = '';
      this.date = '';

      let selectedFacilityIncomeChildren = this.facilitySpaces.filter(item => item.id === space.id).flatMap(item => item.children);
      this.spaceChildren = selectedFacilityIncomeChildren.length ? selectedFacilityIncomeChildren : [];
      this.hasChildren = this.spaceChildren.length > 0;

      if (!this.hasChildren) {
        let selectedSpace = this.facilitySpaces.filter(item => item.id === this.selectedSpace.id);
        this.maxNumberOfParticipants = selectedSpace[0].maxNumberOfParticipants;
        await this.getFacilitiesSpacesAvailableDatesApi();
      }

      this.isDataValid();
    },

    async selectChildSpace(childSpace) {
      this.selectedDate = '';
      this.selectedStartTime = '';
      this.selectedEndTime = '';
      this.date = '';

      this.selectedChildSpace = childSpace;
      let selectedSpace = this.spaceChildren.filter(item => item.id === childSpace.id);
      this.maxNumberOfParticipants = selectedSpace[0].maxNumberOfParticipants;
      await this.getFacilitiesSpacesAvailableDatesApi();
      this.isDataValid();
    },

    changeDate(date) {
      this.date = date
      this.startTimes = [];
      this.endTimes = [];
      this.selectedDate = date;

      this.selectedStartTime = "";
      this.selectedEndTime = "";

      let selectedDate = this.availableDates.filter(item => {
        return `${item.date}` === `${date}`
      });

      if (selectedDate.length) {
        this.startTimes = selectedDate[0].start_interval;
        this.endTimes = selectedDate[0].end_interval;
      }

    },

    onDateChange(date){
      if(date){
        this.getFacilitiesSpacesAvailableDatesApi( date );
      }else{
        this.getFacilitiesSpacesAvailableDatesApi( null );
      }
    },

    selectStartTime(val) {
      this.selectedStartTime = val;
      this.isDataValid();
    },

    selectEndTime(val) {
      this.selectedEndTime = val;
      this.isDataValid();
    },

    removeOrganization() {
      this.selectedOrganization = {};
      this.selectedUser = {};
      this.participants = [];
      this.users = [];
      this.selectedParticipants = [];
    },

    removeUser() {
      this.selectedUser = {};
    },

    async selectOrganization(val) {
      this.selectedOrganization = val;
      this.selectedUser = "";
      this.participants = [];
      this.users = [];
      this.selectedParticipants = [];

      await this.organizationsUtil.getOrganizationUsers(this.selectedOrganization, this.setUsersList);
      this.allUsers = await this.organizationsUtil.getAllUsers();

      this.isDataValid();
    },

    setUsersList(usersData) {
      this.users = usersData;
      this.participants = usersData;
    },

    selectUser(val) {
      this.selectedUser = val;

      this.participants = this.allUsers.filter(item => {
        return item.userId !== val.userId
      });

      this.isDataValid();
    },

    addNewEmailParticipant(val) {
      if (this.rules.email(val) === 'Invalid e-mail.' || val === '') {
        return;
      }

      if (!this.selectedNewParticipants.includes(val)) {
        this.selectedNewParticipants.push(val);
      }

      if (!this.newParticipants.includes(val)) {
        this.newParticipants.push(val);
      }

      this.newParticipantEmail = ''
    },

    removeNewParticipants(val) {
      if (this.newParticipants.includes(val)) {
        this.newParticipants = this.newParticipants.filter(item => item !== val);
      }

      if (this.selectedNewParticipants.includes(val)) {
        this.selectedNewParticipants = this.selectedNewParticipants.filter(item => item !== val);
      }
    },

    selectParticipants(val) {
      this.users = this.users.filter(item => {
        return item.userId !== val.userId
      })
    },

    getObjClone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    deleteItem(item) {
      let list = this.searchField ? this.paginatedSearchedBookingsList : this.paginatedEntitiesList;
      this.editedIndex = list.findIndex(fc => fc.uuid === item.uuid);
      this.editedItem = this.getObjClone(list[this.editedIndex]);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      let deleteUrl = `api/admin-facility-bookings/${this.editedItem.facilityId}/space/${this.editedItem.spaceId}/booking/${this.editedItem.uuid}/user/${this.editedItem.userId}`;
      let item = await ApiRequestService.deleteRequest(deleteUrl);

      if (item.hasOwnProperty('status') && item.status === 200) {
        this.bookingData.data.forEach(item => {
          if (item.uuid === this.editedItem.uuid) {
            item.status = 'canceled';
          }
        })

        await this.paginatedSearchListUtil.updateData(this.bookingData);
        this.updateListData();
        this.closeDelete();
        this.close();
        return;
      }
      this.closeDelete();
      this.close();
      if(item.errors){
        this.showErrorDialog(item.errors);
      }else{
        this.showErrorDialog(item.message);
      }
    },

    showErrorDialog(message) {
      this.dialogError = true;
      this.errorMessage = message;
    },

    async editItem(item) {
      let paginatedList = this.searchField ? this.paginatedSearchedBookingsList : this.paginatedEntitiesList;
      let list = this.searchField ? this.searchedBookingList : this.bookingsList;
      this.editedIndex = paginatedList.findIndex(booking => booking.uuid === item.uuid);
      this.listEditedIndex = list.findIndex(booking => booking.uuid === item.uuid);
      this.editedItem = this.getObjClone(paginatedList[this.editedIndex]);
      this.selectedStatus = this.editedItem.status;


      await this.selectFacility({
        "id": this.editedItem.facilityId,
        name: this.editedItem.facilityName
      });

      await this.initEditItemSpaces();

      this.changeDate(this.getFormattedDateString(this.editedItem.bookingDate));
      this.selectStartTime(DateUtil.getItalianTimeFromUTC(moment().format( 'YYYY-MM-DD' ) + ' ' + this.editedItem.startTime?.substr(0, 5)));
      this.selectEndTime(DateUtil.getItalianTimeFromUTC(moment().format( 'YYYY-MM-DD' ) + ' ' + this.editedItem.endTime?.substr(0, 5)));


      let selectedOrganization = this.organizations.filter(item => {
        return item.id === this.editedItem.organizationId
      });

      await this.selectOrganization(selectedOrganization[0]);

      let selectedUser = this.users.filter(item => {
        return item.userId === this.editedItem.userId
      });

      await this.selectUser(selectedUser[0]);

      this.initEditItemParticipants();

      this.dialog = true
      this.disableKeyField = true
    },

    async initEditItemSpaces() {
      let spaceId = this.editedItem.spaceId;
      let selectedSpace = {};
      let selectedChildSpace = {};

      for (let space of this.facilitySpaces) {
        if (space.id === spaceId) {
          selectedSpace.id = spaceId;
          selectedSpace.name = this.editedItem.spaceName;
          selectedSpace.children = space.children;
          break;
        }

        if (space.children.length) {
          space.children.forEach(childSpace => {
            if (childSpace.id === spaceId) {
              selectedSpace.id = space.id;
              selectedSpace.name = space.name;
              selectedSpace.children = space.children;
              selectedChildSpace.id = spaceId;
              selectedChildSpace.name = this.editedItem.spaceName;
            }
          })
        }
      }

      await this.selectSpace(selectedSpace);

      if (Object.keys(selectedChildSpace).length) {
        await this.selectChildSpace(selectedChildSpace);
      }

    },

    initEditItemParticipants() {
      if (!this.editedItem.participants || !this.editedItem.participants.length) {
        return;
      }

      let participants = [];
      let newParticipants = [];

      const existingParticipant = (email) => this.participants.find((p) => p.email === email);

      this.editedItem.participants.forEach(item => {
        let existingUser = existingParticipant(item.email);
        if (existingUser) {
          participants.push(existingUser);
        } else {
          newParticipants.push(item.email);
        }
      })

      this.selectedParticipants = participants;
      this.selectedNewParticipants = newParticipants;
      this.newParticipants = newParticipants;

    },

    setFacilitiesList(facilitiesData) {
      this.facilities = [];
      this.hasChildren = false;

      facilitiesData?.forEach(item => {
        if (item) {
          this.facilities.push({
            id: item.id,
            name: item.name,
            img: item.photos[0]?.url
          })
        }
      });
    },

    setFacilitySpacesList(spaceData) {
      this.facilitySpaces = [];
      this.spaceChildren = [];
      this.hasChildren = false;

      spaceData?.forEach(item => {
        this.facilitySpaces.push({
          id: item.id,
          name: item.name,
          img: item.photos[0]?.url,
          children: item.childrenSpaces ?? [],
          maxNumberOfParticipants: item.maxNumberOfParticipants ?? 0,
        })
      });
    },

    async getFacilitiesSpacesAvailableDatesApi(date = null) {
      this.dates = [];
      this.availableDates = [];
      let selectedSpace = this.selectedChildSpace ? this.selectedChildSpace.id : this.selectedSpace.id;
      let selectedFacility = this.selectedFacility.id;

      let today = this.editedItem.bookingDate ? new Date(this.editedItem.bookingDate) : date ? new Date(date) : new Date();
      let year = today.getFullYear()
      let month = today.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;

      this.datesData = await ApiRequestService.getRequest(`api/admin-facility-bookings/${selectedFacility}/space/${selectedSpace}/booking/schedule/${year}-${month}`);
      this.setFacilityDatesList(this.datesData);
    },

    setFacilityDatesList(datesData) {
      let today = new Date();
      this.workingDays = datesData?.workingDays?.map( item => item + 1 );

      for (let date in datesData.schedule) {
        let dateTime = new Date(date);

        if (dateTime.getMonth() === today.getMonth() && dateTime.getDate() < today.getDate()) {
          continue;
        }

        let currentDayItem = datesData.schedule[date];
        let formattedDate = this.getFormattedDateString(date);
        let dateItem = {
          date: formattedDate,
          startTime: [],
          endTime: [],
          start_interval: [],
          end_interval: []
        };
        for (let dateDetail of currentDayItem) {
          if (this.editedIndex === -1) {
            if (dateDetail.facilityName != null || dateDetail.spaceName != null) {
              continue;
            }
          } else {
            if (this.editedItem.bookingDate !== date && dateDetail.facilityName !== this.editedItem.facilityName
              && dateDetail.spaceName !== this.editedItem.spaceName && dateDetail.startTime !== this.editedItem.startTime && dateDetail.endTime !== this.editedItem.endTime) {
              continue;
            }
          }

          if (!this.dates.includes(formattedDate)) {
            this.dates.push(formattedDate);
          }
          const startTime = DateUtil.getItalianTimeFromUTC(moment().format( 'YYYY-MM-DD' ) + ' ' + dateDetail.startTime?.substr(0, 5));
          const endTime = DateUtil.getItalianTimeFromUTC(moment().format( 'YYYY-MM-DD' ) + ' ' + dateDetail.endTime?.substr(0, 5));
          dateItem['startTime'].push(startTime);
          dateItem['endTime'].push(endTime);
          dateItem['start_interval'] = dateItem['start_interval'].concat(this.getTimesStartIntervals(startTime, endTime));
          dateItem['end_interval'] = dateItem['end_interval'].concat(this.getTimesEndInterval(startTime, endTime));
        }

        this.availableDates.push(dateItem);
      }

      if (this.date) {
        this.changeDate(this.date)
      }
    },

    getTableFormattedDate(date) {
      return DateUtil.getToLocaleDate(date)
    },

    getToLocaleTime(bookingDate, time) {
      return DateUtil.getItalianTimeFromUTC(moment(bookingDate).format('YYYY-MM-DD') + ' ' + time?.substr(0,5))
    },

    getTimesStartIntervals(startTime, endTime, isCurrentDay) {
      let timeIntervals = [];
      let incomeTime = startTime;

      do {
        timeIntervals.push(incomeTime);

        incomeTime = this.addMinutes(incomeTime);
      } while (incomeTime < endTime)

      return timeIntervals;
    },


    getTimesEndInterval(startTime, endTime) {
      let timeIntervals = [];
      let incomeTime = startTime;

      do {

        incomeTime = this.addMinutes(incomeTime);
        timeIntervals.push(incomeTime);


      } while (incomeTime < endTime)

      return timeIntervals;
    },

    addMinutes(incomeTime, minutes = 30) {
      let date = new Date(`2025-01-01 ${incomeTime}`);
      date.setMinutes(date.getMinutes() + minutes);
      return `${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}:${date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()}`;
    },


    getFormattedDateString(date) {
      if (!(date instanceof Date)) {
        date = new Date(date);
      }

      let getYear = date.toLocaleDateString('default', {year: "numeric"});
      let getMonth = date.toLocaleDateString('default', {month: "2-digit"});
      let getDay = date.toLocaleDateString('default', {day: "2-digit"});

      return `${getYear}-${getMonth}-${getDay}`;
    },

    async save() {
      this.submitted = true;

      if (!this.isDataValid()) {
        return;
      }

      // save or edit
      if (this.editedIndex === -1) {
        await this.saveBooking();
        return;
      }

      await this.updateBooking();
    },

    isDataValid() {
      this.validateUtil.setValidators(this.getValidators());
      return this.validateUtil.isDataValid();
    },

    async saveBooking() {
      let selectedFacility = this.selectedFacility.id;
      let selectedSpace = this.selectedChildSpace ? this.selectedChildSpace.id : this.selectedSpace.id;
      let requestUrl = `api/admin-facility-bookings/${selectedFacility}/space/${selectedSpace}/booking/user/${this.selectedUser.userId}`;
      let result = await ApiRequestService.postRequest(requestUrl, this.getDataModel())
      if (result.uuid) {
        location.reload();
        return;
      }

      if(result.errors){
        this.showErrorDialog(result.errors);
      }else{
        this.showErrorDialog(result.message);
      }
    },

    async updateBooking() {
      if (this.selectedStatus === 'canceled') {
        await this.deleteItemConfirm();
        return;
      }

      let selectedFacility = this.selectedFacility.id;
      let selectedSpace = this.selectedChildSpace ? this.selectedChildSpace.id : this.selectedSpace.id;
      let requestUrl = `api/admin-facility-bookings/${selectedFacility}/space/${selectedSpace}/booking/${this.editedItem.uuid}/user/${this.editedItem.userId}`;
      let result = await ApiRequestService.patchRequest(requestUrl, this.getDataModel());


      if (result.uuid) {
        location.reload();
        return;
      }

      this.closeDelete();
      this.close();
      this.showErrorDialog('Selected time has already booked.You can`t book .');

    },

    getDataModel() {
      let data = {
        bookingDate: momentTimezone.tz(this.selectedDate, TIMEZONE).format('YYYY-MM-DD'),
        startTime: momentTimezone.tz(this.selectedDate + ' ' + this.selectedStartTime?.substr(0,5), TIMEZONE).valueOf(),
        endTime: momentTimezone.tz(this.selectedDate + ' ' + this.selectedEndTime?.substr(0,5), TIMEZONE).valueOf()
      }

      let participants = this.getParticipants();

      if (participants) {
        data.participants = participants;
      }

      return data;
    },

    getParticipants() {
      let participants = [];

      this.selectedParticipants.forEach(item => {
        participants.push(item.email);
      });

      this.selectedNewParticipants.forEach(item => participants.push(item));

      return participants.length ? participants : null;
    },

    close() {
      this.dialog = false;
      this.submitted = false;
      this.errorKey = '';
      this.selectedFacility = '';

      this.selectedSpace = '';
      this.selectedChildSpace = '';
      this.selectedDate = '';
      this.selectedStartTime = '';
      this.selectedEndTime = '';
      this.newParticipantEmail = '';
      this.selectedOrganization = '';
      this.selectedUser = '';
      this.selectedParticipants = [];
      this.selectedNewParticipants = [];
      this.selectedStatus = '';
      this.date = '';
      this.facilitySpaces = [];
      this.spaceChildren = [];
      this.hasChildren = false;
      this.maxNumberOfParticipants = 0;
      this.dates = [];
      this.startTimes = [];
      this.endTimes = [];
      this.newParticipants = [];
      this.participants = [];
      this.users = [];
      this.availableDates = [];

      /**
       * if action is edit, set to form old data
       */
      let data;
      if (this.editedIndex !== -1) {
        data = this.getObjClone(this.editedItem);
      } else {
        data = this.getObjClone(this.defaultItem);
      }

      this.$nextTick(() => {
        this.editedItem = {...this.getObjClone(this.defaultItem)};
        this.editedIndex = -1;
      })
    },

    closeDelete() {
      this.dialogDelete = false;
      this.submitted = false;

      this.$nextTick(() => {
        this.editedItem = {...this.getObjClone(this.defaultItem)};
        this.editedIndex = -1;
      })
    },

    closeErrorDialog() {
      this.dialogError = false;
    },

    setOpacityToNotActiveBookings() {
      setTimeout(()=>{
        const isNotActives = document.querySelectorAll('.isNotActive');
        const isActives = document.querySelectorAll('.isActive');

        if(isNotActives && isNotActives.length){
          for(let isNotActive of isNotActives){
            isNotActive.closest('tr').style = 'opacity: 0.7';
          }
        }
        if(isActives && isActives.length){
          for(let isActive of isActives){
            isActive.closest('tr').style = 'opacity: 1';
          }
        }
      },300)
  },
  }
}


