import ApiRequestService from "@/services/ApiRequestService";

export default class OrganizationsUtil {


  constructor(apiUrl = 'api/admin-organizations', organizations = [], selectOrganizationCallBack) {
    this.adminOrganizationsApi = apiUrl;
    this.organizations = organizations;
    this.selectOrganizationCallBack = selectOrganizationCallBack;
  }

  async getOrganizations() {
    let organizationsData = await ApiRequestService.getRequest(this.adminOrganizationsApi);
    await this.setOrganizationsList(organizationsData);
  }

  setOrganizationsList(organizationsData) {
    let organizations = [];

    organizationsData.data.forEach(item => {
      this.organizations.push({id: item.id, name: item.name});
    })

    if (organizationsData.data.length === 1) {
      this.selectOrganizationCallBack(organizationsData.data[0]);
    }
  }

  async getAllUsers( ) {
    let users = await ApiRequestService.getRequest(`api/users/all-member`) ?? [];
    let usersData = [];

    users.forEach(item => {
      item.name = `${item.firstName} ${item.lastName}`
      usersData.push(item);
    });

    return usersData;
  }

  async getOrganizationUsers(selectedOrganization, setUsersCallBack ) {
    let users = await ApiRequestService.getRequest(`api/admin-users/organization/${selectedOrganization.id}`) ?? [];
    let usersData = [];

    users.forEach(item => {
      item.name = `${item.firstName} ${item.lastName}`
      usersData.push(item);
    });

    setUsersCallBack(usersData);
  }


}
