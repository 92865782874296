import ApiRequestService from "@/services/ApiRequestService";
import Vue from "vue";

export default class PaginatedSearchListUtil {

  constructor(remoteApi, itemsPerPage, sortColumnName = null, desc = false) {
    this.remoteApi = remoteApi;
    this.entityData = {};
    this.pagesCount = 0;
    this.itemsPerPage = itemsPerPage;
    this.currentPage = 1;
    this.searchedDataOriginal = {};
    this.searchedEntityPaginatedList = {};
    this.entityPaginatedList = {};
    this.paginatedEntitiesList = [];
    this.searchedEntityList = [];
    this.entityList = [];
    this.paginatedSearchedEntityList = [];
    this.sortByDesc = desc;
    this.sortByColumn = sortColumnName;
    this.additionalParams = {};
  }

  /**
   * Setting unique id foe each data
   * @param uniqueField
   */
  setDataUniqueField(uniqueField) {
    this.uniqueField = uniqueField
  }

  setDataModel(model) {
    this.model = model;
  }

  async getListData(page) {
    this.paginatedEntitiesList = [];
    this.currentPage = page;
    let responseData = await ApiRequestService.getRequest(this.remoteApi + this.getSortQuery(true));

    if (!responseData) {
      return;
    }

    this.entityData = responseData;
    await this.getDataListPaginated();
  }

  async getDataListPaginated(searchField, page) {
    this.searchField = searchField;
    this.currentPage = page;

    let itemsCount = this.entityData.totalElements;
    this.pagesCount = Math.ceil(this.entityData.totalElements / this.itemsPerPage);

    if (itemsCount) {
      await this.setEntitiesToEntitiesList(this.entityData.data);
    }
  }

  async setEntitiesToEntitiesList(incomeData) {
    this.entityPaginatedList = {};
    this.entityList = [];
    incomeData?.forEach((dataObject, index) => {
      let existsIndex = this.entityList.findIndex(item => item[`${this.uniqueField}`] === dataObject?.[`${this.uniqueField}`]);

      let incomeEntityData = {};

      for (let key in this.model) {
        incomeEntityData[key] = dataObject[key];
      }

      if (existsIndex !== -1) {
        Vue.set(this.entityList, existsIndex, incomeEntityData)
        let entitiesPaginatedListClone = [...this.entityPaginatedList[this.currentPage].values()]

        entitiesPaginatedListClone.forEach(item => {
          if (incomeEntityData[`${this.uniqueField}`] === item[`${this.uniqueField}`]) {
            this.entityPaginatedList[this.currentPage].delete(item)
            this.entityPaginatedList[this.currentPage].add(incomeEntityData)
          }
        });
      }

      Vue.set(this.entityList, this.entityList.length, incomeEntityData)

      if (!this.entityPaginatedList[this.currentPage]) {
        this.entityPaginatedList[this.currentPage] = new Set();
      }

      if (this.entityPaginatedList[this.currentPage].size < this.itemsPerPage) {
        this.entityPaginatedList[this.currentPage].add(incomeEntityData);
      }
    })

    if (this.entityPaginatedList[this.currentPage]) {
      this.paginatedEntitiesList = this.getObjClone([...this.entityPaginatedList[this.currentPage]])
    }
  }

  searchText(searchText) {
    return ApiRequestService.getRequest(`${this.remoteApi}?searchText=${searchText}${this.getSortQuery()}`);
  }

  setSortColumn(columnName, desc) {
    this.sortByColumn = columnName ?? null;
    this.sortByDesc = !!desc;
    return this.changePage(this.searchField, this.currentPage);
  }

  setAdditionalParam(paramName, paramValue) {
    if (paramValue?.trim()) {
      this.additionalParams[paramName] = paramValue;
    } else {
      delete this.additionalParams[paramName];
    }
  }

  getSortQuery(comesFirst = false) {
    let query = '';

    if (this.sortByColumn) {
      query += `${comesFirst ? '?' : '&'}sortBy=${this.sortByColumn}&direction=${this.sortByDesc ? 'DESC' : 'ASC'}`;
    }

    for (const key in this.additionalParams) {
      query += `${comesFirst && !query ? '?' : '&'}${key}=${encodeURIComponent(this.additionalParams[key])}`;
    }

    return query;
  }


  async getSearchedEntitiesPaginated(searchText, page) {
    this.searchField = searchText;
    this.currentPage = page;

    let data = await this.searchText(searchText);
    this.searchedEntityList = [];
    this.searchedEntityPaginatedList = {};

    this.pagesCount = Math.ceil(data.totalElements / this.itemsPerPage)
    this.paginatedSearchedEntityList.length = 0;

    await this.setSearchedEntitiesToEntities(data.data);

    this.searchedDataOriginal = data;
    this.searchedData = this.getObjClone(this.searchedEntityList);
  }


  async changePage(searchField, page) {
    this.searchField = searchField;
    this.currentPage = page;

    if (this.searchField) {
      this.searchedEntityPaginatedList = {};
      this.searchedDataOriginal = await ApiRequestService.getRequest(`${this.remoteApi}?page=${this.currentPage}&searchText=${this.searchField}${this.getSortQuery()}`);

      if (this.searchedDataOriginal?.data?.length) {
        await this.setSearchedEntitiesToEntities(this.searchedDataOriginal.data);
      }
      return;
    }

    this.entityData = await ApiRequestService.getRequest(`${this.remoteApi}?page=${this.currentPage}${this.getSortQuery()}`);

    if (this.entityData?.data?.length) {
      await this.setEntitiesToEntitiesList(this.entityData.data);
    }
  }

  async setSearchedEntitiesToEntities(data) {
    data.forEach((dataObject, index) => {

      let incomeEntityData = {};

      for (let key in this.model) {
        incomeEntityData[key] = dataObject[key];
      }

      if (!this.searchedEntityPaginatedList[this.currentPage]) {
        this.searchedEntityPaginatedList[this.currentPage] = [];
      }

      if (this.searchedEntityPaginatedList[this.currentPage].length < this.itemsPerPage) {
        this.searchedEntityPaginatedList[this.currentPage].push(incomeEntityData);
      }

    })

    if (this.searchedEntityPaginatedList[this.currentPage]) {
      this.paginatedSearchedEntityList = this.getObjClone(this.searchedEntityPaginatedList[this.currentPage])
    }
  }

  async updateData(entityData) {
    this.entityData = entityData;
    await this.getDataListPaginated();
  }


  getObjClone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

}
